/* div:has( > h2, > h3) { */
/* h2, h3, :is(h2, h3) > span { */
/* h2, h3 { */
.main-heading {
    /* display: block; */
    margin: 32px 0px;
    text-align: center;
}
.main-heading > h2 {
    font-size: 24px;
    font-weight: bold;
}

.sub-heading {
    margin: 16px 0px;
    font-size: 18.72px;
    font-weight: bold;
}