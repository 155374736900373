/* Start by resetting some styles */
.fc .fc-toolbar-title {
  color: var(--neutral-black-black-500, #1d1f2c);

  /* Text XL/Semibold */
  font-family: "Public Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  letter-spacing: 0.18px;
}

.fc .fc-button-primary {
  background-color: #f0f7fc; /* Background color for buttons */
  color: #1f9cb9; /* Text color for buttons */
  border-radius: 8px;
  font-family: "Public Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.07px;
  border: none;
  padding: 8px 12px;
}

.fc .fc-button-primary:hover {
  background-color: #1f9cb9; /* Background color for buttons */
  color: #f0f7fc; /* Text color for buttons */
}
.fc .fc-button-primary:disabled {
  background-color: #1f9cb9; /* Background color for buttons */
  color: #f0f7fc; /* Text color for buttons */
}

.fc-timegrid-event .fc-event-time {
  overflow: hidden;
  color: var(--primary-primary-500, #883dcf);
  text-overflow: ellipsis;
  white-space: nowrap;

  /* Text S/Semibold */
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  letter-spacing: 0.06px;
}
.fc-v-event .fc-event-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--neutral-black-black-500, #883dcf);

  /* Text S/Semibold */
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  letter-spacing: 0.06px;
}

.fc-v-event {
  border: none;
}

/* Custom event colors */
/* .fc-event {
  background-color: #e1efff; 
  border: 1px solid #e1efff; 
  color: #333; 
}


.fc-event.grooming {
  background-color: #dadafe; 

} */

/* Continue with custom styles for each part of the calendar */
/* ... */
